<template>
  <el-container>
    <el-main>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="公共模版" name="Public template">
          <grid-layout ref="gridLayOutPublic" :grid-row-btn="gridRowBtn" :table-options="tableOptions"
                       :table-data="publicTableData" :table-loading="publicLoading">
            <template #customBtn="{ row }">
              <el-button style="margin: 0 3px" type="text" size="small" @click="selectData(row)">选择</el-button>
            </template>
          </grid-layout>
        </el-tab-pane>
        <el-tab-pane label="自定义模版" name="Custom template">
          <grid-layout ref="gridLayOut" :grid-row-btn="gridRowBtn" :table-options="tableOptions" :table-data="tableData"
                       :table-loading="loading">
            <template #customBtn="{ row }">
              <el-button style="margin: 0 3px" type="text" size="small" @click="selectData(row)">选择</el-button>
            </template>
          </grid-layout>
        </el-tab-pane>
      </el-tabs>
    </el-main>
  </el-container>
</template>

<script>
import {ticketTemplateList} from "@/api/workTicket"
import GridLayout from "@/views/components/layout/grid-layout";
import {mapGetters} from "vuex";

export default {
  name: "templateType",
  components: {
    GridLayout,
  },
  props: {
    dataFormExt: {},
  },
  data() {
    return {
      activeName: "Public template",
      templateType: "公共模版",
      loading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
      tableData: [],
      publicLoading: true,
      publicPage: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
      publicDataTotal: 0,
      dataTotal: 0,
      publicTableData: [],
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    gridRowBtn() {
      return []
    },
    tableOptions() {
      return {
        menu: true,
        menuWidth: 150,
        selection: false,
        column: [
          {
            label: "模版编号",
            prop: "code",
            align: 'center',
            overHidden: true,
          },
          {
            label: "作业票类型",
            prop: "ticketType",
            type: "select",
            align: 'center',
            overHidden: true,
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=WORK_TICKET_TYEP",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
          },
          {
            label: "模版说明",
            prop: "name",
            align: 'center',
            overHidden: true,
          },
        ]
      }
    },
  },
  methods: {
    handleClick(val) {
      if (val.name === "Public template") {
        this.onLoadPublic(this.publicPage);
      } else if (val.name === "Custom template") {
        this.onLoad(this.page);
      }
    },
    selectData(data) {
      this.$emit("selectData", data);
    },
    onLoad(page, params = {}) {
      this.loading = true;
      this.page = page;
      ticketTemplateList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, {templateType: '自定义模版', organizationId: this.userInfo.dept_id, isEnable: 1})
      ).then((res) => {
        const data = res.data.data;
        this.tableData = data.records;
        this.loading = false;
        this.$nextTick(() => {
          this.$refs.gridLayOut.$refs.grid.$refs.table.doLayout();
        })
      }).finally(() => {
        this.loading = false;
      });
    },
    onLoadPublic(page, params = {}) {
      this.publicLoading = true;
      this.publicPage = page;
      ticketTemplateList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, {templateType: '公共模版', isEnable: 1})
      ).then((res) => {
        const data = res.data.data;
        this.publicTableData = data.records;
        this.$nextTick(() => {
          this.$refs.gridLayOutPublic.$refs.grid.$refs.table.doLayout();
        })
        this.publicLoading = false;
      }).finally(() => {
        this.publicLoading = false;
      });
    },
  },
  created() {
    this.onLoadPublic(this.publicPage)
  },
}
</script>

<style scoped>

</style>
