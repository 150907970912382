var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.deptBelowData,
            size: "medium",
            "row-key": "id",
            border: "",
            height: "calc(100vh - 293px)",
            "tree-props": { children: "children", hasChildren: "title" },
            "default-expand-all": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "deptName", label: "组织名称" },
          }),
          _c("el-table-column", {
            attrs: { prop: "institutionType", label: "类型" },
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.chooseDeptBelow(scope.row)
                          },
                        },
                      },
                      [_vm._v("\n          确定\n        ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }