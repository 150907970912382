var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "bottomBtn" },
      [
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.emptyCanvas } },
          [_vm._v("清空")]
        ),
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.useDefault } },
          [_vm._v("使用默认签名")]
        ),
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.confirmCanvas } },
          [_vm._v("确定")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "canvasBox" }, [
      _c("canvas", { staticStyle: { display: "block" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }