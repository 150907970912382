<template>
  <div>
    <head-layout head-title="安全监管"></head-layout>
    <form-layout ref="formLayout" :column="formColumn" :dataForm.sync="superviseData"></form-layout>
  </div>
</template>
<script>
import ServerNameEnum from "@/util/ServerNameEnum";
import FormLayout from "@/views/components/layout/form-layout";
import { dateFormat } from "@/util/date";
import { mapGetters } from "vuex";
export default {
  components: {
    FormLayout
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(["userInfo"]),
    formColumn() {
      return [
        {
          label: "现场照片",
          prop: "supervision",
          span: 24,
          disabled: !this.disabled,
          listType: "picture-card",
          type: "upload",
          action: `${ServerNameEnum.SERVER_RESOURCE_}/oss/endpoint/put-file`,
          propsHttp: {
            res: "data",
            url: "link",
          },
          canvasOption: {
            text: `${dateFormat(new Date())}/${this.userInfo.nick_name}`,
            color: 'red',
            fontSize: '10'
          },
        },
      ]
    }
  },
  data() {
    return {
      superviseData: {
        supervision: ""
      },
    };
  },
};
</script>
