<template>
  <div>
    <div class="canvasBox">
      <canvas style="display: block"/>
    </div>
    <div class="bottomBtn">
      <el-button type="primary" @click="emptyCanvas">清空</el-button>
      <el-button type="primary" @click="useDefault">使用默认签名</el-button>
      <el-button type="primary" @click="confirmCanvas">确定</el-button>
    </div>
  </div>
</template>
<script>
import {getUserInfo, putFile} from "@/api/system/user";
import SmoothSignature from "smooth-signature";

export default {
  data() {
    return {
      signatureImg:''
    }
  },
  mounted() {
    this.init();
    // 获取用户基本信息拿取设置好的电子签名
    getUserInfo().then((res) => {
      if (res.data.code == 200) {
        this.signatureImg = res.data.data.electronicSignature;
      }
    })
  },
  methods: {
    // 清空画布
    emptyCanvas() {
      this.signature.clear();
    },
    // 清空画布
    init() {
      const canvas = document.querySelector("canvas");
      const options = {
        width: 960,
        height: 400,
        minWidth: 4,
        maxWidth: 12,
        bgColor: '#f6f6f6'
      };
      this.signature = new SmoothSignature(canvas, options);
    },
    // 使用默认签名
    useDefault() {
      this.$emit('getSignatureImg',this.signatureImg)
    },
    // 确定电子签名
    confirmCanvas() {
      const isEmpty = this.signature.isEmpty();
      console.log(isEmpty,'63')
      if (isEmpty) {
        this.$message({
          type: "warning",
          message: "请绘制电子签名"
        });
        return;
      }
      const pngUrl = this.signature.getPNG();
      let file = this.base64toFile(pngUrl,Date.now())
      const formData = new FormData()
      formData.append('file', file);
      putFile(formData).then((res) =>{
        if (res.data.code == 200) {
          this.$emit('getSignatureImg',res.data.data.link)
        }
      })
    },
    base64toFile(urlString, fileName) {
      const dataArr = urlString.split(",");
      const byteString = atob(dataArr[1]);
      const options = {
        type: "image/png",
        endings: "native"
      };
      const u8Arr = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        u8Arr[i] = byteString.charCodeAt(i);
      }
      return new File([u8Arr], fileName + ".png", options);//返回文件流
    },
  }
}
</script>
<style lang="scss" scoped>
.canvasBox {
  width: 100%;
  height: 400px;
}

.bottomBtn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
}
</style>
