var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    [
      _c(
        "el-main",
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "公共模版", name: "Public template" } },
                [
                  _c("grid-layout", {
                    ref: "gridLayOutPublic",
                    attrs: {
                      "grid-row-btn": _vm.gridRowBtn,
                      "table-options": _vm.tableOptions,
                      "table-data": _vm.publicTableData,
                      "table-loading": _vm.publicLoading,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "customBtn",
                        fn: function ({ row }) {
                          return [
                            _c(
                              "el-button",
                              {
                                staticStyle: { margin: "0 3px" },
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.selectData(row)
                                  },
                                },
                              },
                              [_vm._v("选择")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "自定义模版", name: "Custom template" } },
                [
                  _c("grid-layout", {
                    ref: "gridLayOut",
                    attrs: {
                      "grid-row-btn": _vm.gridRowBtn,
                      "table-options": _vm.tableOptions,
                      "table-data": _vm.tableData,
                      "table-loading": _vm.loading,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "customBtn",
                        fn: function ({ row }) {
                          return [
                            _c(
                              "el-button",
                              {
                                staticStyle: { margin: "0 3px" },
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.selectData(row)
                                  },
                                },
                              },
                              [_vm._v("选择")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }