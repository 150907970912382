<template>
  <div class="center">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="我的" name="my">
        <!--列表label-->
        <head-layout
          head-title="作业票清单"
          :head-btn-options="headBtnOptions"
        ></head-layout>
        <!--列表查询条件和头部按钮-->
        <grid-head-layout
          ref="gridHeadLayoutMy"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>
        <grid-layout
          ref="gridLayOutMy"
          :table-options="option"
          :table-data="myTableData"
          v-if="activeName == 'my'"
          :table-loading="myLoading"
          :data-total="myPage.total"
          :grid-row-btn="gridRowBtn"
          :page="myPage"
          @page-current-change="onLoadMyPage"
          @page-size-change="onLoadMyPage"
          @gird-handle-select-click="selectionChange"
        >
          <template #customBtn="{ row }">
            <el-button
              style="margin: 0 3px"
              type="text"
              size="small"
              @click="submit(row)"
            >确定
            </el-button
            >
          </template>
        </grid-layout>
      </el-tab-pane>
      <el-tab-pane label="全部" name="all">
        <!--列表label-->
        <head-layout
          v-if="activeName == 'my'"
          head-title="作业票清单"
          :head-btn-options="headBtnOptions"
        ></head-layout>
        <!--列表查询条件和头部按钮-->
        <grid-head-layout
          ref="gridHeadLayoutAll"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>
        <grid-layout
          ref="gridLayOutAll"
          v-if="activeName == 'all'"
          :table-options="option"
          :table-data="tableData"
          :table-loading="loading"
          :data-total="page.total"
          :grid-row-btn="gridRowBtn"
          :page="page"
          @page-current-change="onLoad"
          @page-size-change="onLoad"
          @gird-handle-select-click="selectionChange"
        >
          <template #customBtn="{ row }">
            <el-button
              style="margin: 0 3px"
              type="text"
              size="small"
              @click="submit(row)"
            >确定
            </el-button
            >
          </template>
        </grid-layout>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import {detail, getTaskUser} from "@/api/plugin/workflow/process";
import {
  ticketInventoryList,
  ticketType,
  myWtTicketPage, currentlyOrganized,
} from "@/api/workTicket";
import {mapGetters} from "vuex";
import CommonDialog from "@/components/CommonDialog";
import TemplateType from '@/views/business/jobSlip/jobSlipInventory/components/templateType';
import WfFlow from "@/views/plugin/workflow/process/components/flow.vue";

export default {
  data() {
    return {
      loading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      tableData: [],
      myLoading: true,
      myPage: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      myTableData: [],
      selectionList: [],
      activeName: "my",
      kictectType: false,
      kictectTypeList: [],
      searchColumns: [
        {
          prop: "ticketCode",
          type: "input",
          span: 4,
          placeholder: "请输入作业票编号",
        },
        {
          prop: "ticketType",
          span: 4,
          placeholder: "请选择作业票类型",
          type: "select",
          dicUrl:
            "/api/sinoma-system/dict-biz/dictionary?code=WT_TICKET_TYPE",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          dataType: "String",
        },
        {
          prop: "workSupervisorName",
          type: "input",
          span: 4,
          placeholder: "请输入作业负责人",
        },
        {
          prop: "createUserName",
          type: "input",
          span: 3,
          placeholder: "请输入创建人",
        },
        {
          prop: "businessStatus",
          type: "select",
          span: 4,
          placeholder: "请选择状态",
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=WT_STATUS",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          dataType: "String",
        },
      ],
      bpmnVisible: false,
      bpmnOption: {},
      circulationVisible: false,
    };
  },
  components: {HeadLayout, GridLayout, CommonDialog, WfFlow, TemplateType},
  mixins: [exForm],
  props: {
    //新增作业票编号:
    ticketCode: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters(["userInfo", "language"]),
    headBtnOptions() {
      return [];
    },
    gridRowBtn() {
      return [];
    },
    option() {
      return {
        linklabel: "code",
        selection: false,
        column: [
          {
            label: "作业票编号",
            prop: "ticketCode",
            align: "left",
            overHidden: true,
          },
          {
            label: "作业票类型",
            type: "select",
            align: "center",
            dicUrl:
              "/api/sinoma-system/dict-biz/dictionary?code=WT_TICKET_TYPE",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            prop: "ticketType",
            overHidden: true,
          },
          {
            label: "作业内容",
            prop: "content",
            align: "center",
            overHidden: true,
          },
          {
            label: "作业实施时间",
            prop: "workExecutionTime",
            align: "center",
            overHidden: true,
          },
          {
            label: "申请人",
            prop: "createUserName",
            align: "center",
            overHidden: true,
          },
          {
            label: "作业负责人",
            prop: "workSupervisorName",
            align: "center",
            overHidden: true,
          },
          {
            label: "申请时间",
            prop: "createTime",
            align: "center",
            overHidden: true,
          },
          {
            label: "状态",
            prop: "businessStatus",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=WT_STATUS",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            align: "center",
            overHidden: true,
          },
        ],
      };
    },
  },
  created() {
    this.onLoadMyPage(this.myPage);
  },
  mounted() {
    window.addEventListener("storage", (e) => {
      if (e.key === "sinoma-refresh") {
        if (this.activeName == "my") {
          this.myPage.currentPage = 1;
          this.onLoadMyPage(this.myPage);
        } else if (this.activeName == "all") {
          this.page.currentPage = 1;
          this.onLoad(this.page);
        }
        localStorage.setItem("sinoma-refresh", JSON.stringify(false)); //变成false , 可以来回监听
      }
    });
  },

  methods: {
    submit(row) {
      this.$emit('submitData', row)
    },
    handleClick(val) {
      if (val.name == "all") {
        this.$refs.gridHeadLayoutAll.searchForm = {};
        this.page.currentPage = 1;
        this.onLoad(this.page);
      } else if (val.name == "my") {
        this.$refs.gridHeadLayoutMy.searchForm = {};
        this.myPage.currentPage = 1;
        this.onLoadMyPage(this.myPage);
      }
    },
    // 列表查询
    gridHeadSearch(searchForm) {
      if (this.activeName == "my") {
        this.myPage.currentPage = 1;
        this.onLoadMyPage(this.myPage, searchForm);
      } else if (this.activeName == "all") {
        this.page.currentPage = 1;
        this.onLoad(this.page, searchForm);
      }
    },
    // 查询重置
    gridHeadEmpty(searchForm) {
      if (this.activeName == "my") {
        this.myPage.currentPage = 1;
        this.onLoadMyPage(this.myPage, searchForm);
      } else if (this.activeName == "all") {
        this.page.currentPage = 1;
        this.onLoad(this.page, searchForm);
      }
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    onLoad(page, params = {}) {
      let that = this;
      that.page = page;
      that.loading = true;
      currentlyOrganized(
        page.currentPage,
        page.pageSize,
        Object.assign(params, {code: this.ticketCode})
      ).then((res) => {
        const data = res.data.data;
        that.page.total = data.total;
        that.$nextTick(() => {
          that.$refs.gridLayOutAll.page.total = data.total;
        });
        that.tableData = data.records;
        that.loading = false;
      });
    },
    onLoadMyPage(page, params = {}) {
      let that = this;
      that.myPage = page;
      that.myLoading = true;
      currentlyOrganized(
        page.currentPage,
        page.pageSize,
        Object.assign(params, {
          createUser: that.userInfo.user_id,
          code: this.ticketCode
        })
      ).then((res) => {
        const data = res.data.data;
        that.myPage.total = data.total;
        that.$nextTick(() => {
          that.$refs.gridLayOutMy.page.total = data.total;
        });
        that.myTableData = data.records;
        that.myLoading = false;
      });
    },
  },
};
</script>

<style scoped lang='scss'>
::v-deep .avue-crud .el-table {
  height: calc(100vh - 320px) !important;
  max-height: calc(100vh - 320px) !important;
}

::v-deep .el-tabs__header {
  padding: 0 12px;
  margin: 0;
  background: #fff;
}

::v-deep .el-dialog__body {
  padding: 20px;
}

.kicket-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .kicket {
    width: 140px;
    text-align: center;
    margin: 20px;
  }
}

::v-deep .bjs-powered-by {
  display: none;
}
</style>
